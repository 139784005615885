import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import axios from 'axios'
//import './index.css'
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import VueScrollmagic from 'vue-scrollmagic'
Vue.use(VueScrollmagic)
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
 
Vue.use(VueFileAgent);

import VueScrollTo from 'vue-scrollto'
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.config.productionTip = false

Vue.prototype.$sleep = function(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
Vue.prototype.$http = axios

Vue.prototype.$store = store

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
