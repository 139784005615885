<template>
  <div id="start" v-if="content.pageData" class="relative font-body max-w-screen-2xl mx-auto antialiased text-[#41454c] bg-[#FFFFFF]">
    <div v-if="ui.showPrivacyModal" @click="ui.showPrivacyModal = false" style="z-index:69" class="overlay fixed w-full h-full left-0 top-0 backdrop-blur-sm">
    </div>
    <transition name="modal">
      <div v-if="ui.showPrivacyModal" class="privacy-modal z-40 p-10 bg-white text-left">
        <div class="close" @click="ui.showPrivacyModal = false">x</div>
        <h1 class="font-medium text-xl">Datenschutz</h1>
        <div class="text mt-12">
          <h2 class="font-medium">Webserver</h2>
          <p class="mt-4">
            Der Webserver erhebt Daten über Zugriffe auf die Seite und speichert diese als „Server-Logfiles“ ab. Folgende Daten werden so protokolliert:
          </p>
          <ul class="list-disc list-inside mt-2">
            <li>
              Besuchte Website / Ressource
            </li>
            <li>
              Uhrzeit zum Zeitpunkt des Zugriffes
            </li>
            <li>
              Menge der gesendeten Daten in Byte
            </li>
            <li>
              Quelle/Verweis, von welchem Sie auf die Seite gelangten
            </li>
            <li>
              Verwendeter Browser
            </li>
            <li>
              Verwendetes Betriebssystem
            </li>
            <li>
              Verwendete IP-Adresse (in anonymisierter Form)
            </li>
          </ul>
          <br>
          Die erhobenen Daten dienen statistischen Auswertungen.<br>
          Außerdem dienen sie zur Erkennung und Abwehr von bösartigen Angriffen auf die Server-Infrastruktur.<br>
          Die Daten werden nicht an Dritte weitergegeben, es sei denn der Serverbetreiber wird durch richterliche/staatsanwaltschaftliche Anordnung dazu verfügt.<br>
          Datensätze werden maximal 2 Monate aufbewahrt und werden danach automatisch gelöscht.<br>
          Der Serverstandort dieser Website liegt in Deutschland.
          <h2 class="font-medium mt-7">Cookies</h2>
          <p class="mt-4">
            Diese Website setzt keine Cookies auf dem Gerät des Besuchers.<br>
          </p>
          <h2 class="font-medium mt-7">Kontaktformular</h2>
          <p class="mt-4">
            Bei der Nutzung des Kontaktformulars, werden unterschiedliche Daten erhoben.<br>
            Pflichtfeld ist die Eingabe der E-Mail-Adresse, um auf die Anfrage antworten zu können.<br>
            Alle anderen Daten sind freiwilliger Natur.<br>
            Mit dem Klick auf "senden" werden die Daten an unser Backend geschickt, welches eine E-Mail TLS verschlüsselt
            an uns sendet. Das Backend speichert diese Daten nicht.<br>
            Ihre gesendeten Daten speichern wir so lange, wie sie für unsere Kommunikation, Angebotserstellung oder Kunden-Service notwendig ist.<br>
            Sie können zu jeder Zeit eine Löschung ihrer Daten verlangen. Senden Sie uns dafür bitte einfach eine Mail an <a :href="'mailto:'+content.pageData.Email" class="underline underline-offset-2">{{content.pageData.Email}}</a>.
            <br><br>
          </p>
        </div>
      </div>
    </transition>
    <transition name="menu">
      <div class="fixed top-0 left-0 w-full h-full bg-white bg-opacity-80 backdrop-blur-sm z-50" v-if="ui.showMobileMenu">
        <div class="text-4xl absolute right-8 top-5 cursor-pointer" @click="ui.showMobileMenu = false">
          X
        </div>
        <div class="flex h-3/4 flex-col justify-center items-center gap-y-7 text-4xl">
          <transition appear name="menuitem">
            <div :key="'m1'" @click="ui.showMobileMenu = false; $scrollTo('#start')">Start</div>
          </transition>
          <transition appear name="menuitem">
            <div class="delay-75" :key="'m2'" @click="ui.showMobileMenu = false; $scrollTo('#leistungen')">Leistungen</div>
          </transition>
          <transition appear name="menuitem">
            <div class="delay-100" :key="'m3'" @click="ui.showMobileMenu = false; $scrollTo('#projekte')">Projekte</div>
          </transition>
          <transition appear name="menuitem">
            <div class="delay-150" :key="'m4'" @click="ui.showMobileMenu = false; $scrollTo('#karriere')">Karriere</div>
          </transition>
          <transition appear name="menuitem">
            <div class="delay-200" :key="'m5'" @click="ui.showMobileMenu = false; $scrollTo('#kontakt')">Kontakt</div>
          </transition>
        </div>
      </div>
    </transition>
    <div id="menubar" class="max-w-full sticky top-0 bg-white z-40 shadow-gray-400 transition-all duration-700 bg-opacity-90 backdrop-blur-sm backdrop-filter shadow-md" style="height:68px">
      <div class="px-8 py-0 h-full">
        <div class="relative flex items-center w-full min-w-full md:justify-between justify-between h-full">
          <div class="hidden md:block md:visible md:w-3/12">
            <div class="p-2"></div>
          </div>
          <ul class="md:flex hidden md:visible space-x-8 justify-evenly w-7/12 text-lg lg:flex">
            <li class=""><a href="#" class="font-medium tracking-wide transition-colors duration-200 hover:text-red-500 " v-scroll-to="'#start'">Start</a></li>
            <li class=""><a href="#" class="font-medium tracking-wide transition-colors duration-200 hover:text-red-500 " v-scroll-to="'#leistungen'">Leistungen</a></li>
            <li class=""><a href="#" class="font-medium tracking-wide transition-colors duration-200 hover:text-red-500 " v-scroll-to="'#projekte'">Projekte</a></li>
            <li class=""><a href="#" class="font-medium tracking-wide transition-colors duration-200 hover:text-red-500 " v-scroll-to="'#karriere'">Karriere</a></li>
            <li class=""><a href="#" class="font-medium tracking-wide transition-colors duration-200 hover:text-red-500 " v-scroll-to="'#kontakt'">Kontakt</a></li>
          </ul>
          <div class="w-2/12 text-right text-sm text-red-500 flex-col hidden md:flex">
            <div id="header-contact-container" class="border-l-2 self-end pl-5">
              <div><a :href="'mailto:'+content.pageData.Email">{{content.pageData.Email}}</a></div>
              <div><a :href="'tel:'+content.pageData.Telefon">{{content.pageData.Telefon}}</a></div> 
            </div>
          </div>
          <div class="md:hidden w-full text-right text-3xl">
            <div @click="ui.showMobileMenu = true">☰</div>
          </div>
        </div>
      </div>
    </div>
    <section style="height:80vh" class="overflow-hidden relative bg-no-repeat bg-cover bg-center">
      <img alt="No alt" id="kobralogo" src="@/assets/img/kobra_logo_c.png" class="fixed left-0 right-0 md:left-auto md:right-auto md:ml-5 top-20 md:w-1/3 w-10/12 mx-auto z-30 " />
      <div class="absolute top-0 left-0 w-full h-full bg-white opacity-50 z-20"></div>
      <div class="absolute z-20 w-full h-28 bg-white -rotate-6 scale-150 -bottom-16"></div>
      <div class="absolute left-0 top-0 w-full h-full z-10">
        <vueper-slides ref="myVueperSlides" :fixed-height="'100%'" :autoplay="true" :parallax="1" :fade="true" :duration="6000" :transitionSpeed="1500" :parallax-fixed-content="false" :arrows="false" :bullets="false">
          <vueper-slide
            v-for="slide of content.pageData.Hero_Bilder" :key="slide.id"
            :image="store.app.apiUrl+'/assets/'+slide.directus_files_id+'?format='+imgFormat" />
        </vueper-slides>
      </div>
    </section>
    <div class="relative z-30 md:-top-48 -top-36" id="trigger1">
      <div class="mx-auto relative lg:items-center lg:flex lg:max-w-7xl">
        <div class="max-w-xl mx-auto text-center flex flex-col items-center bg-white p-8 shadow-2xl z-30 relative">
          <h1 class="text-4xl font-extrabold text-slate-900 lg:text-5xl ">
            <p class="text-4xl md:text-7xl bg-clip-border font-medium text-gray-800 normal-case"><span class="">Willkommen bei Ko-Bra</span></p>
            <p class="text-base mt-2"><span class="">Korrosions- und Brandschutzbeschichtungen</span></p>
          </h1><img alt="No alt" src="@/assets/img/phrase_c.png" class="block w-64 content-center place-items-center mt-4 ease-in transition-all scale-100   " />
        </div>
      </div>
    </div>
    <div class="overflow-hidden mb-20 md:-mt-24 -mt-16">
      <div class="container mx-auto px-4 mb-4 flex justify-center">
         <div class="w-full md:w-7/12 md:p-10 bg-white md:shadow-md" v-html="content.pageData.Einleitungstext">
        </div>
      </div>
    </div>
    <section id="kontakt-stoerer" class="overflow-hidden text-gray-600 body-font -900 from-red-500 to-yellow-500 bg-none bg-red-500 md:block">
      <div class="text-center py-10 px-4 md:w-10/12 md:mx-auto lg:w-2/3 lg:px-4">
        <h1 id="contact-stoerer-text" class="flex-grow text-xl md:text-2xl font-medium title-font text-center text-white">{{content.pageData.Stoerer_Kontakt_Text}}</h1>
        <div class="mt-5 bg-transparent xl:text-center xl:mt-6"><a href="#" id="contact-stoerer-link" class="bg-white inline-block px-6 py-3 rounded-global xl:p-3 xl:text-lg xl:font-medium xl:text-current" v-scroll-to="'#kontakt'">{{content.pageData.Stoerer_Kontakt_Button_Text}}</a></div>
      </div>
    </section>
    <div class="overflow-hidden flex flex-col py-20" id="leistungen">
      <div class="text-center flex flex-col gap-y-3 px-2 sm:mx-auto sm:max-w-xl">
        <h1 class="font-bold text-3xl text-gray-700 sm:text-4xl">Leistungen</h1>
        <p class="text-base sm:text-lg">davon verstehen wir was!</p>
      </div>
      <div class="mt-14 flex flex-col md:flex md:items-center lg:flex-row lg:inline lg:items-center lg:w-10/12 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2 xl:gap-x-14 gap-y-6">
        <div class="px-4 lg:col-span-1 space-y-5">
          <img v-for="image of content.pageData.Leistungen_Bilder" :key="image.id" 
          :src="store.app.apiUrl+'/assets/'+image.directus_files_id+'?width=580&format='+imgFormat" class="shadow-xl rounded-xl" />
        </div>
        <div class="px-4 mt-5 flex flex-col gap-y-3 ml-2 lg:col-span-1 xl:space-y-10">
          <div class="relative" v-for="(item, index) of content.pageData.Leistungen" :key="index">
            <Leistungen :text="item.Text" :title="item.Titel" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center overflow-hidden py-10 text-center bg-gray-200">
      <h1 class="font-bold text-3xl text-gray-700 sm:text-4xl">Unsere Partner</h1>
      <div class="w-11/12 mt-4 md:hidden">
        <vueper-slides
          class="no-shadow"
          :3d="false"
          autoplay
          fixed-height="180px">
          <vueper-slide v-for="image of content.pageData.Partner_Bilder" :key="image.id">
            <template #content>
              <div class="p-2 flex justify-center items-center">
                <img alt="No alt" :src="store.app.apiUrl+'/assets/'+image.directus_files_id+'?format='+imgFormat" class="max-w-full max-h-28" />
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="hidden md:grid gap-x-12 gap-y-4 px-4 grid-cols-5 items-center sm:grid-cols-3 sm:gap-x-12 sm:gap-y-3 sm:px-9 md:grid-cols-4 lg:max-w-4xl lg:mx-auto xl:grid-cols-5">
        <div class="" v-for="image of content.pageData.Partner_Bilder" :key="image.id">
          <img alt="No alt" :src="store.app.apiUrl+'/assets/'+image.directus_files_id+'?format='+imgFormat" class="w-full " />
        </div>
      </div>
    </div>
    <div class="overflow-hidden py-20 flex flex-col items-center" id="projekte">
      <div class="text-center flex flex-col gap-y-3 px-2 sm:mx-auto sm:max-w-xl">
        <h1 class="font-bold text-3xl text-gray-700 sm:text-4xl">Projekte</h1>
      </div>
      <div class="mt-14 w-11/12 md:w-9/12">
        <vueper-slides
          class="no-shadow"
          :arrowsOutside="false"
          :gap="5"
          :autoplay="store.app.breakpoint == 'sm' ? true : false"
          :slideMultiple="true"
          :visible-slides="store.app.breakpoint == 'sm' ? 1 : 2"
          :dragging-distance="70"
          :fixed-height="store.app.breakpoint == 'sm' ? '480px' : '440px'">
          <vueper-slide v-for="projekt of content.projekte" :key="projekt.id">
            <template #content>
              <div>
                <div class="flex flex-col items-center justify-center max-w-lg mx-auto">
                  <div class="w-full h-80 bg-gray-300 bg-center bg-cover rounded-lg shadow-md" :style="'background-image: url('+store.app.apiUrl+'/assets/'+projekt.Bild+'?width=512&format='+imgFormat+')'">
                  </div>
                  <div class="projekt-info-box scale-0 transform w-64 -mt-10 overflow-hidden bg-white rounded-lg shadow-lg md:w-64">
                    <h3 class="py-2 px-3 font-bold tracking-wide text-center text-gray-800">
                      {{projekt.Name}}
                    </h3>
                    <div class="flex flex-col px-3 py-2 bg-gray-200">
                      <div class="text-gray-800 whitespace-pre-line">
                        {{projekt.Infos}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
        
      </div>
    </div>
    <section class="overflow-hidden flex flex-col items-center auto-rows-fr bg-gray-100 py-20" id="karriere">
      <div class="text-center flex flex-col gap-y-3 px-2 sm:mx-auto sm:max-w-xl">
        <h1 class="font-bold text-3xl text-gray-700 sm:text-4xl">{{content.pageData.Karriere_Titel}}</h1>
      </div>
      <div class="gap-y-8 px-6 mt-14 w-full md:w-10/12 flex flex-col justify-center">
        <div class="w-12/12">
          <div class="text-lg">
            <div class="md:w-8/12 w-full" v-html="content.pageData.Karriere_Text_1">
            </div>
            <div id="jobcards" class="mt-10 w-12/12 gap-4 flex flex-col md:flex-row relative">
              <div v-for="item of content.pageData.Karriere_Ups" :key="item.Zeile_1" v class="flex justify-center flex-col text-center jobcard w-full md:w-3/12 opacity-0 scale-150 p-4 shadow-md rounded-global bg-white pt-2 transition-all duration-500 transform hover:scale-125 hover:z-50 relative">
                <h6 class=" mt-2 font-medium text-gray-900">{{item.Zeile_1}}</h6>
                <p class="mt-1 text-sm sm:block" v-if="item.Zeile_2">{{item.Zeile_2}}</p>
              </div>
            </div>
            <div class="karriere-text mt-10 w-full md:w-8/12" v-html="content.pageData.Karriere_Text_2">
            </div>
          </div>
          <div class="text-left hidden">
            <button @click="toggleBrandschutz()" class="mt-10 inline-block rounded-global transition bg-red-500 hover:bg-gray-700 text-white font-medium p-1 px-4 py-2 shadow-xl ">Kontakt aufnehmen</button>
          </div>
        </div>
      </div>
    </section>
    <section class="overflow-hidden py-20" id="kontakt">
      <div class="text-center flex flex-col gap-y-3 px-2 sm:mx-auto sm:max-w-xl">
        <h1 class="font-bold text-3xl text-gray-700 sm:text-4xl">Kontakt</h1>
      </div>
      <div class="px-4 mx-auto max-w-7xl md:px-8">
        <transition name="contactform" mode="out-in">
          <div :key="'mail1'" v-if="!ui.mailSent" class="max-w-screen-md grid sm:grid-cols-2 gap-4 mx-auto mt-16">
            <div class="sm:col-span-2 flex flex-row mb-2"><input @change="calcSubject()" v-model="contactForm.angebotsanfrage" type="checkbox" class="h-6 w-6 bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /><label class="text-gray-800 text-sm sm:text-base ml-2">Angebotsanfrage</label></div>
            <transition name="formin">
            <div class="sm:col-span-2 grid grid-cols-1 gap-y-2 md:grid-cols-4 mb-4 mt-1" v-if="contactForm.angebotsanfrage">
              <div>
                Angebot für:
              </div>
              <div class="flex flex-row">
                <input @change="calcSubject()" v-model="contactForm.korrosionsschutz" type="checkbox" class="h-5 w-5 bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /><label class="text-gray-800 text-sm sm:text-base ml-2">Korrosionsschutz</label>
              </div>
              <div class="flex flex-row">
                <input @change="calcSubject()" v-model="contactForm.brandschutz" type="checkbox" class="h-5 w-5 bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /><label class="text-gray-800 text-sm sm:text-base ml-2">Brandschutz</label>
              </div>
              <div class="flex flex-row">
                <input @change="calcSubject()" v-model="contactForm.sonstiges" type="checkbox" class="h-5 w-5 bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /><label class="text-gray-800 text-sm sm:text-base ml-2">Sonstiges</label>
              </div>
            </div>
            </transition>
            <transition name="formin">
            <div v-if="contactForm.angebotsanfrage" class=""><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Ort</label><input v-model="contactForm.ort" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            </transition>
            <transition name="formin">
            <div v-if="contactForm.angebotsanfrage" class=""><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Ausführungsbeginn</label><input v-model="contactForm.beginn" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            </transition>
            <div class=""><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Firma</label><input v-model="contactForm.firma" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            <div class=""><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Ansprechpartner</label><input v-model="contactForm.ansprechpartner" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            <div class="">
              <label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Telefon</label>
              <input v-model="contactForm.telefon" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
            </div>
            <div class="">
              <label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Email*</label>
              <input @blur="$v.contactForm.email.$touch()" v-model="contactForm.email" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" />
              <div v-if="$v.contactForm.email.$invalid && $v.contactForm.email.$dirty" class="text-red-500 text-xs absolute">Bitte geben Sie eine valide Email-Adresse ein</div>
            </div>
            <div class=""><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Ihre Referenz</label><input v-model="contactForm.referenz" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            <div class="sm:col-span-2"><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Betreff*</label><input @keyup="keyupSubject()" v-model="contactForm.betreff" class="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" /></div>
            <div class="sm:col-span-2"><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Nachricht*</label><textarea v-model="contactForm.nachricht" class="w-full h-64 bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"></textarea></div>
            <div class="sm:col-span-2"><label class="inline-block text-gray-800 text-sm sm:text-base mb-2">Dateien <small>(max. 10 Dateien, max. 10MB)</small></label>
              <VueFileAgent 
              v-model="fileRecords"
              ref="vueFileAgent"
              :theme="'list'"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/*,.zip,.pdf,.doc,.docx'"
              :maxSize="'10MB'"
              :maxFiles="10"
              :helpText="'Bilder, PDF, DOC und ZIP werden akzeptiert'"
              :errorText="{
                type: 'Dieser Dateityp ist nicht erlaubt',
                size: 'Die Dateien sollten eine Größe von 10MB nicht überschreiten',
              }"
              @select="filesSelected($event)"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)">
              </VueFileAgent>
              <div v-if="compFileSizeToBig" class="text-red-500 text-xs absolute">Ihre Dateien dürfen insgesamt nicht größer als 10MB sein.</div>
            </div>
            <div class="sm:col-span-2 flex justify-between items-center">
              <button :disabled="$v.contactForm.$invalid || compFileSizeToBig" v-if="!ui.mailSending" @click="sendContactForm()" class="disabled:opacity-75 disabled:hover:bg-red-500 w-48 mt-2 inline-block rounded-global transition bg-red-500 hover:bg-gray-700 text-white font-medium p-1 px-4 py-2 shadow-xl ">
                Senden
              </button>
              <div v-else class="mt-2 loader"></div> 
              <div v-if="ui.mailSending" class="text-red-500 mt-3">
                {{uploadPercentage}} %
              </div>
              <span class="text-gray-500 text-sm">*Pflichtfeld</span>
            </div>
            <div v-if="ui.showMailError" class="mt-2 text-red-500">
              Entschuldigung, es ist ein Fehler aufgetreten. Bitte nehmen Sie in diesem Fall kontakt per Mail auf (<a class="underline underline-offset-2" href="mailto:info@kobra.berlin">info@kobra.berlin</a>)
            </div>
          </div>
          <div :key="'mail2'" v-else class="max-w-screen-md text-center mt-12 md:text-left md:mt-16 text-xl mx-auto">
            Vielen Dank für Ihre Nachricht!<br>
            Wir werden so schnell wie möglich mit Ihnen Kontakt aufnehmen.
            <button @click="ui.mailSent = !ui.mailSent" class="text-base mt-8 inline-block rounded-global transition bg-red-500 hover:bg-gray-700 text-white font-medium p-1 px-4 py-2 shadow-xl ">
              Kontaktformular erneut anzeigen
            </button>
          </div>
        </transition>
      </div>
    </section>
    <section class="bg-gray-200 py-16 flex flex-row justify-center">
      <div class="overflow-hidden w-10/12 grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-2">
        <div>
          <h1 class="font-bold text-2xl text-gray-700">Impressum</h1>
          <div class="text-sm mt-4 leading-relaxed">
            <div v-html="content.pageData.Impressum">
            </div>
            <div @click="ui.showPrivacyModal = true" class="underline underline-offset-2 cursor-pointer mt-10">Datenschutzerkärung</div>
          </div>
        </div>
        <div class="">
          <h1 class="font-bold text-2xl text-gray-700">Kontakt</h1>
          <div class="text-sm mt-4 leading-relaxed underline underline-offset-2">
            Tel: <a class="" :href="'tel:'+content.pageData.Telefon">{{content.pageData.Telefon}}</a><br>
            E-Mail: <a class="" :href="'mailto:'+content.pageData.Email">{{content.pageData.Email}}</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Leistungen from '@/components/leistungen.vue'

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import {store} from '../store'

export default {
  name: 'Home',
  components: {
    Leistungen, VueperSlides, VueperSlide
  },
  async mounted() {
    let c = await this.checkWebP()
    if (c) this.imgFormat = 'webp'
    await this.getData()
    // Declare Scene
    let logoHeight = "55px"
    let logoTop = "5px"
    if (this.store.app.breakpoint == 'sm') {
      logoTop = "15px"
      logoHeight = "40px"
    }
    const scene2 = this.$scrollmagic.scene({
      // ID of element where animation starts
      triggerElement: '#trigger1',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      triggerHook: 0.5,
      duration: 300
    })
    .setTween('#kobralogo', { 
      css: { 
        top:logoTop,
        width:'auto',
        zIndex:"49",
        height:logoHeight,
        left:'unset',
        right: 'unset',
        marginLeft: "1.25rem"
      }
    })
    const scene3 = this.$scrollmagic.scene({
      // ID of element where animation starts
      triggerElement: '#projekte',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      triggerHook: 0.5,
      duration: 0
    })
    .setTween('.projekt-info-box', { 
      css: { 
        transform: "scale(1)",
      }
    })
    .on("start", (event) => {
      scene3.enabled(false)
    });
    var tween = TweenMax.staggerTo(".jobcard", 1, {duration: 1, transform: "scale(1)", opacity: 1}, 0.8);
    const scene4 = this.$scrollmagic.scene({
      // ID of element where animation starts
      triggerElement: '#karriere',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      //triggerHook: 0.5,
      duration: 200
    })
    .setTween(tween)
    .on("end", (event) => {
      //scene4.enabled(false)
      //scene4.remove()
    });
    var tween5 = TweenMax.fromTo('#header-contact-container', 1, { 
      css: { 
        position: "relative",
        transform: "translate(-70px, 90px) scale(2)",
        fontWeight: "bold",
        borderLeftWidth: "0px",
        borderColor: 'rgba(229, 231, 235, 0)'
      }},{
      css: {
        position: "relative",
        transform: "translate(0px, 0px) scale(1)",
        fontWeight: "normal",
        borderLeftWidth: "2px",
        borderColor: 'rgba(229, 231, 235, 1)'
      }})
    const scene5 = this.$scrollmagic.scene({
      // ID of element where animation starts
      triggerElement: '#trigger1',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      triggerHook: 0.5,
      duration: 300
    })
    .setTween(tween5)
    let tl = new TimelineMax()
    var tweenStoerer = TweenMax.fromTo('#kontakt-stoerer', 1, { 
        x: "-100vw",
        filter: "blur(5px)",
        opacity:0
      },
      {
        x: "-0%",
        filter: "blur(0px)",
        opacity:1
      }
      )
    //tl.add(tween6).add(tween7).add(tween8);
    const scene6 = this.$scrollmagic.scene({
      // ID of element where animation starts
      triggerElement: '#kontakt-stoerer',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      triggerHook: 0.7,
      duration: 300
    })
    .setTween(tweenStoerer)
  
    // Add Scene to controller
    this.$scrollmagic.addScene(scene2)
    this.$scrollmagic.addScene(scene3)
    this.$scrollmagic.addScene(scene4)
    this.$scrollmagic.addScene(scene5)
    this.$scrollmagic.addScene(scene6)

    console.log(this.$refs)
    this.$refs.myVueperSlides.doAutoplay()
  },
  data() {
    return {
      store: store.state,
      uploadPercentage: 0,
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadUrl: 'https://example.com',
      subjectManuallyChanged: false,
      imgFormat: '',
      content: {
        pageData: {},
        projekte: []
      },
      contactForm: {
        angebotsanfrage: false,
        korrosionsschutz: false,
        brandschutz: false,
        sontiges: false,
        ort: '',
        beginn: '',
        referenz: '',
        firma: '',
        ansprechpartner: '',
        telefon: '',
        email: '',
        betreff: '',
        nachricht: ''
      },
      ui: {
        showMobileMenu: false,
        showBrandschutz: false,
        showMailError: false,
        mailSending: false,
        mailSent: false,
        showPrivacyModal: false
      }
    }
  },
  setup: () => ({ $v: useVuelidate() }),
  validations () {
    return {
      contactForm: {
        betreff: { required },
        nachricht: { required },
        email: { required, email }
      }
    }
  },
  computed: {
    compFileSizeToBig() {
      let size = this.fileRecordsForUpload.reduce((p,c) => {
        return p + c.size
      },0)
      if (size > 10485760) return true
      return false
    }
  },
  methods: {
    checkWebP() {
      return new Promise(res => {
        const webP = new Image();
        webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        webP.onload = webP.onerror = () => {
            res(webP.height === 2);
        };        
      })
    },
    async getData() {
      try {
        /*
        let [pageData, servicesImages, partnerImages] = await Promise.all([this.$http.get(this.store.app.apiUrl+"/items/pagecontent"), this.$http.get(this.store.app.apiUrl+"/items/pagecontent_files"), this.$http.get(this.store.app.apiUrl+"/items/pagecontent_files_1")]);
        console.log(this.content)
        this.content.pageData = pageData.data.data
        this.content.servicesImages = servicesImages.data.data
        this.content.partnerImages = partnerImages.data.data
        */
        let [pageData, projekte] = await Promise.all([
          this.$http.get(this.store.app.apiUrl+"/items/pagecontent?fields=*.*"),
          this.$http.get(this.store.app.apiUrl+"/items/projekte")
        ]);
        this.content.pageData = pageData.data.data
        this.content.projekte = projekte.data.data
        console.log("content", this.content)
      } catch (err) {
        console.log(err)
      }
    },
    toggleBrandschutz() {
      let el = this.$refs.brandschutzElement
      console.log(el)
      console.log(el.scrollHeight)
      el.style.maxHeight = el.scrollHeight + 'px'
      this.ui.showBrandschutz = true
    },
    calcSubject() {
      if (this.subjectManuallyChanged) return
      let str = ''
      if (this.contactForm.angebotsanfrage) {
        str = str + "Angebotsanfrage"
      }
      if (this.contactForm.korrosionsschutz) {
        str = str + " Korrosionsschutz"
      }
      if (this.contactForm.brandschutz) {
        if (this.contactForm.korrosionsschutz) {
          str = str + " & Brandschutz"
        } else {
          str = str + " Brandschutz"
        }
      }
      this.contactForm.betreff = str
    },
    keyupSubject() {
      console.log("keyup  subject")
      if (this.contactForm.betreff) this.subjectManuallyChanged = true
      else this.subjectManuallyChanged = false
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord); 
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      console.log(this.fileRecordsForUpload)
    },
    fileDeleted: function (fileRecord) {
      console.log("delete file", fileRecord)
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      }
    },
    async sendContactForm() {
      this.ui.mailSending = true
      this.$sleep(1000)
      console.log("send contact form", this.store, window.document.location.hostname)
      try {
        /*
        await fetch(this.store.app.apiUrl + '/contactform', {
          method: 'POST', // or 'PUT'
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.contactForm),
        })
        */
        var formData = new FormData();
        console.log(this.fileRecordsForUpload)
        //formData.append('file', this.fileRecordsForUpload[0])
        //await this.$http.post(this.store.app.apiUrl + '/items/contactForm', this.contactForm)
        formData.append('item', JSON.stringify(this.contactForm))
        for (let file of this.fileRecordsForUpload) {
          console.log("file", file)
          formData.append("files", file.file )
        }
        await this.$http.post(this.store.app.apiUrl + '/contactform', formData, { headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: ProgressEvent => {
          this.uploadPercentage = parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          );
        }})
        this.ui.mailSent = true
      } catch (err) {
        console.error(err)
        this.ui.showMailError = true
      }
      this.ui.mailSending = false
      if (this.ui.mailSent) {
        this.$scrollTo('#kontakt')
      }
    }
  }
}
</script>

<style lang="stylus">
.vueperslides__arrows {
  color: rgba(239, 68, 68, 1);
}
.vueperslides__arrows svg {
  stroke-width: 2
}
.vueperslide {
  transition: transform 0.4s ease
}
.vueperslide--active {
}
.vueperslides__arrow--prev {
  left: -4.5rem!important
}
.vueperslides__arrow--next {
  right: -4.5rem!important
}
span {
 text-underline-offset: 2px;
}
.karriere-text {
  ul {
    list-style-type: disc!important
    list-style-position: outside!important
    li {
      margin-left: 1.25rem!important
    }
  }
}
</style>

<style lang="stylus" scoped>
::-v-deep .vueperslides__arrows {
  color: #000!important;
}
.fadeout-enter-active {
  transition: all .3s ease;
}
.fadeout-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fadeout-enter, .fadeout-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(40px);
  opacity: 0;
}

.formin-enter-active {
  transition: all 1s ease;
  max-height:80px
}
.formin-leave-active {
  transition: all 1s ease;
  max-height:80px
  //overflow:hidden
}
.formin-enter {
  max-height:0px
  opacity:0
}
.formin-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  //transform: translateY(40px);
  opacity: 0;
  max-height:0px
}

.contactform-enter-active {
  transition: all 1s ease;
}
.contactform-leave-active {
  transition: all 1s ease;
}
.contactform-enter, .contactform-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-1000px);
  opacity: 0;
}

.menu-enter-active {
  transition: all 0.7s ease;
}
.menu-leave-active {
  transition: all 0.7s ease;
}
.menu-enter, .menu-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}

.menuitem-enter-active {
  transition: all 0.7s ease;
}
.menuitem-leave-active {
  transition: all 0.7s ease;
}
.menuitem-enter, .menuitem-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.menuitem2-enter-active {
  transition: all 0.7s ease;
}
.menuitem2-leave-active {
  transition: all 0.7s ease;
}
.menuitem2-enter, .menuitem2-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fb0006;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1s linear infinite;
}
.loader::after {
  animation-delay: 0s;
}
@media (max-width: 768px) {
  .privacy-modal {
    width:98vw!important
    top:1vh!important
    height:98vh!important
  }
}
.privacy-modal {
  position:fixed
  left:0;
  right:0;
  margin: 0 auto;
  width:70%
  top:5vh
  height:90vh
  z-index:70
  border:2px solid #292929
  overflow:auto
}
.close {
  position:absolute
  cursor:pointer
  right:30px
  top:15px
  font-size:60px
  font-family: sans-serif;
  color: #292929
  font-weight:100
  line-height:1
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.modal-enter-active {
  transition: all 0.5s ease;
}
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter {
  transform:translate(-130%,-130%) scale(1)
  transform:translate(-50%, 50%) scale(0)
}
.modal-leave-to {
  //transform:translate(-130%,-130%) scale(0)
  transform: translate(-50%, 50%) scale(0)
}
</style>