<template>
  <div id="app" class="bg-gray-200">
    <home v-if="store.app.breakpoint" />
  </div>
</template>

<script>
import Home from '@/views/Home.vue'
import {store} from './store'

export default {
  components: {
    Home
  },
  data() {
    return {
      store: store.state
    }
  },
  mounted() {
    store.setBreakpoint(window.innerWidth)
    window.addEventListener(
      'resize',
      store.setBreakpoint(window.innerWidth),
      false
    )
  }
}
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700;800;900&display=swap');
/*
body {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif!important
}
*/

</style>

<style>
.font-body{font-family:DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}
</style>
