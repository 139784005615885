import Vue from 'vue'

const state = new Vue({
  data() {
    return {
      state: {
        app: {
          apiUrl: window.document.location.hostname == 'localhost' ? 'http://localhost:8055' : 'https://backend.kobra.berlin',
          breakpoint: null
        },
        ui: {

        }
      }
    }
  },
  computed: {
    
  },
  methods: {
    setBreakpoint(width) {
      if (width <= 640) {
        this.state.app.breakpoint = 'sm'
      } else if (width <= 768) {
        this.state.app.breakpoint = 'md'
      } else if (width <= 1024) {
        this.state.app.breakpoint = 'lg'
      } else if (width <= 1280) {
        this.state.app.breakpoint = 'xl'
      } else {
        this.state.app.breakpoint = '2xl'
      }
      console.log("set breakpoint: ", this.state.app.breakpoint)
    }
  }
})

export const store = state
