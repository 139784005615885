<template>
  <div class="relative">
    <transition name="fadeout" v-if="compShowCuttedText">
      <div class="p-2 absolute bottom-0 left-0 h-32 w-full bg-gradient-to-b from-transparent to-white flex flex-col justify-end items-center" v-if="!ui.showText">
        <button @click="toggleText()" id="nehvslq-55" class="inline-block rounded-global transition bg-red-500 hover:bg-gray-700 text-white font-medium p-1 px-4 py-2 shadow-xl ">mehr erfahren</button>
      </div>
    </transition>
    <h1 class="font-bold text-3xl my-5 tracking-tight text-gray-700 sm:text-5xl md:my-1 lg:my-1 xl:my-1 xl:text-2xl">
      {{title}}
    </h1>
    <div class="overflow-hidden" v-if="compShowCuttedText">
      <p ref="textElement" class="overflow-hidden max-h-48 transition-all duration-700" v-html="text">
      </p>
    </div>
    <p v-else v-html="text"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ui: {
        showText: false
      }
    }
  },
  mounted() {
    
  },
  props: {
    title: String,
    text: String
  },
  computed: {
    compShowCuttedText() {
      if (!this.text) return false
      if (this.text.length < 300) return false
      return true
    }
  },
  methods: {
    toggleText() {
      let el = this.$refs.textElement
      console.log(el)
      console.log(el.scrollHeight)
      el.style.maxHeight = el.scrollHeight + 'px'
      this.ui.showText = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.fadeout-enter-active {
  transition: all .3s ease;
}
.fadeout-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fadeout-enter, .fadeout-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(40px);
  opacity: 0;
}
</style>